@import '../../styles/variables.scss';

.nav {
    width: 100%;
    height: max-content;
    padding: 15px;
    background: $color-bg-variant;
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-radius: 5px;

    @include breakpoint(md) {
        border-radius: 0px;
    }

    &__title {
        width: 80%;
        margin: auto;
        color: $color-text;
        text-align: center;
        font-size: $font-size-medium;
        font-weight: $font-weight-medium;
        line-height: 18px;
    }

    &__button {
        width: 100%;
        height: 42px;
        padding: 2px 15px;
        cursor: pointer;
        color: $color-text;
        font-size: $font-size-small;
        font-weight: $font-weight-small;
        background: $color-primary;
        border-radius: 5px;
        border: none;
    
        &:hover {
            color: $color-light;
            background: $color-primary-variant;
        }
    
        &:active {
            background: transparent;
            color: $color-primary-variant;
            border: 2px solid $color-primary-variant;
        }
    
        &:disabled {
            background: transparent;
            color: $color-primary-variant;
            border: 2px solid $color-primary-variant;
        }
    }
}