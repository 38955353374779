@import '../../styles/variables.scss';

.spinner {
    border: 10px solid $color-text;
    border-top: 10px solid $color-bg-variant;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
    display: flex;
    justify-self: center;
    align-self: center;

    @keyframes spin {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
    }
}