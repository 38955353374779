@import '../styles/variables.scss';

.main {
    position: relative;
    width: 100%;
    height: 100vh;
    background: $image-bg;

    @include breakpoint(md) {
        height: 100%;
    }

    &__section {
        padding: 20px 35px;
        display: grid;
        grid-template-columns: 23% 75%;
        gap: 2%;
        overflow: hidden;
        height: 90%;

        @include breakpoint(md) {
            height: 1200px;
            padding: 0px;
            grid-template-columns: 1fr;
            grid-gap: 0px;
        }
    }
}