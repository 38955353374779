@import './variables.scss';

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    width: 100%;
    font-family: $poppins;
}

#root {
    height: 100%;
}